<template>
    <div class="caseManagement">
        <header>
            <el-form :inline="true" :model="query" class="demo-form-inline">
                <el-form-item label="系统模块：">
                    <el-select v-model="query.title" class="search-input" placeholder="请选择">
                        <el-option label="全部" value="" />
                        <el-option v-for="item in systemOptions" :key="item" :label="item" :value="item" />
                    </el-select>
                </el-form-item>
                <el-form-item label="操作人：">
                    <el-input clearable v-model="query.operName" placeholder="请输入" />
                </el-form-item>
                <el-form-item label="行政区划：" prop="divisionName">
                    <el-input  v-model="query.divisionName" /> 
                </el-form-item>
                <el-form-item label="操作时间：" prop="searchData">
                <el-date-picker
                        v-model="searchData"
                        type="datetimerange"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        :size="size"
                        @change="handleDate"
                        value-format="YYYY-MM-DD HH:mm"
                        format="YYYY-MM-DD HH:mm"
                    />
                </el-form-item>
                <el-form-item label="状态：">
                    <el-select v-model="query.status" class="search-input" placeholder="请选择">
                        <el-option
                            v-for="item in statusOption"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        />
                    </el-select>
                </el-form-item>
            </el-form>
            <div class="btns">
                <el-button @click="search" type="primary">查 询</el-button>
                <el-button @click="resize" plain>重 置</el-button>
            </div>
        </header>
        <main>
            <div class="subToolbar">
                <el-button type="danger" v-if="userBtns.logmanagement_empty" @click="cleanAll">清 空</el-button>
                <el-button
                    type="danger"
                    v-if="userBtns.logmanagement_delete"
                    :disabled="checkData.length < 1"
                    @click="deleteData"
                    >删 除</el-button
                >
            </div>
            <div class="table">
                <el-table
                    :data="tableData"
                    :default-sort="{ prop: 'createTime', order: 'descending' }"
                    style="width: 100%; height: 100%"
                    header-row-class-name="table-header"
                    height="calc(100% - 40px)"
                    :header-cell-style="{
                        background: '#F7F8FA',
                        color: '#333333',
                        height: '54px',
                        'font-size': '14px',
                    }"
                    @selection-change="handleSelectionChange"
                >
                    <el-table-column type="selection" width="55" />
                    <el-table-column type="index" width="70" label="序号" />
                    <el-table-column prop="title" label="系统模块"></el-table-column>
                    <el-table-column prop="businessTypeName" label="操作类型"></el-table-column>
                    <el-table-column prop="operName" label="操作人"></el-table-column>
                    <!-- <el-table-column prop="operIp" label="操作地址"> </el-table-column>
                    <el-table-column prop="operLocation" label="操作地点"></el-table-column> -->
                    <el-table-column prop="operTime" sortable label="操作时间"> </el-table-column>
                    <el-table-column prop="deptName" label="操作人部门"></el-table-column>
                    <el-table-column prop="divisionName" label="所属行政区域"></el-table-column>
                    <el-table-column prop="status" label="操作状态">
                        <template #default="{ row }">
                            <div>
                                <span
                                    :class="['status', 'status' + row.status]"
                                    v-if="row.status == '0'"
                                    slot="reference"
                                    >• 成功</span
                                >
                                <span :class="['status', 'status' + row.status]" v-else slot="reference">• 失败</span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination">
                    <div>
                        <el-pagination
                            @size-change="getList"
                            @current-change="getList"
                            v-model:currentPage="query.page"
                            v-model:page-size="query.size"
                            :page-sizes="[15, 30, 50, 100]"
                            background
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>
<script setup>
import { ref, reactive, computed, watch, onMounted, onUnmounted, getCurrentInstance } from 'vue';
import axios from 'axios';
import $store from '@/store';
import { useRouter } from 'vue-router';
import { ElMessage, ElMessageBox } from 'element-plus';
import { useStore } from 'vuex';

const { proxy } = getCurrentInstance();
let store = useStore();
const roles = store.state.user.roles;
function isDisables(role) {
    let res = true;
    role.forEach((e) => {
        if (roles.indexOf(e) > -1) {
            res = false;
        }
        return res;
    });
    return res;
}
const router = useRouter();
const emit = defineEmits(['close']);
const props = defineProps({});
let query = ref({
    page: 1,
    size: 15,
    status: '',
    operName: '',
    operTime: '',
    searchValue: '',
    title: '',
});
let searchData = ref([])
let checkData = ref([]);
let total = ref(0);
const statusOption = ref([
    {
        label: '全部',
        value: '',
    },
    {
        label: '成功',
        value: 0,
    },
    {
        label: '失败',
        value: 1,
    },
]);
const options = ref([
    {
        label: '其它',
        value: 0,
    },
    {
        label: '新增',
        value: 1,
    },
    {
        label: '修改',
        value: 2,
    },
    {
        label: '删除',
        value: 3,
    },
    {
        label: '授权',
        value: 4,
    },
    {
        label: '导出',
        value: 5,
    },
    {
        label: '导入',
        value: 6,
    },
    {
        label: '强退',
        value: 7,
    },
    {
        label: '生成代码',
        value: 8,
    },
    {
        label: '清空数据',
        value: 9,
    },
    {
        label: '登录',
        value: 10,
    },
    {
        label: '查询',
        value: 11,
    },
]);
const systemOptions = ref([
    '登录管理',
    '消息中心',
    '任务上报',
    '任务审核',
    '任务执行',
    '问题发现',
    '问题分类',
    '问题评估',
    '问题审核',
    '日志管理',
    '驾驶舱配置',
    '数据维护',
]);
let tableData = ref([]);
function handleSelectionChange(val) {
    checkData.value = val;
}
function getOption() {
    axios({
        url: '/system/dict/data/list',
        method: 'get',
        params: {
            dictType: 'system_module',
        },
    }).then((res) => {
        systemOptions.value = res;
    });
}
async function getList() {
    axios({
        url: '/front/sysOperLog/getPage',
        method: 'get',
        params: query.value,
    }).then((res) => {
        if (res) {
            tableData.value = res.records;
            tableData.value.forEach((e) => {
                e.businessTypeText = e.businessType ? options.value[e.businessType].label : '';
            });
            total.value = res.total;
            console.log(tableData.value, options.value[0]);
        }
    });
}
function search() {
    query.value.page = 1;
    getList();
}
function handleDate(val) {
    if(searchData.value){
        query.value.timeBegin = searchData.value[0]
        query.value.timeEnd  = searchData.value[1]
    }
    console.log(query.value);
}
function resize() {
    query.value = {
        page: 1,
        size: 15,
        status: null,
        operName: '',
        operTime: null,
        searchValue: null,
    };
    searchData.value = []
    getList();
}
function cleanAll() {
    ElMessageBox.alert('此操作将永久清空全部数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
    })
        .then(() => {
            axios.get(`/front/sysOperLog/cleanOperLog`).then((res) => {
                ElMessage({
                    message: '清空成功',
                    type: 'success',
                });
                getList();
            });
        })
        .catch(() => {
            ElMessage({
                message: '取消清空',
                type: 'warning',
            });
        });
}
async function deleteData() {
    let ids = checkData.value.map((e) => e.operId).join(',');
    const res = await axios({
        url: '/front/sysOperLog/delete',
        method: 'get',
        params: {
            ids: ids,
        },
    }).then((res) => {
        ElMessage.success('删除成功');
        getList();
    });
}

const test = computed(() => {
    return null;
}); // 获取按钮权限
const userBtns = computed(() => {
    return store.state.user.userBtns;
});
watch(() => {});
onMounted(() => {
    // getOption();
    getList();
});
onUnmounted(() => {});
</script>
<style lang="stylus" scoped>
.caseManagement {
    height: calc(100% - 32px);
    header {
        height: 114px;
        background: #ffffff;
        box-shadow: 0 0 10px -6px rgba(0, 0, 0, 0.3);
        border-radius: 2px;
        display: flex;
        justify-content: space-between;
        padding: 16px 24px 0 24px;
        box-sizing: border-box;
        .btns {
            white-space: nowrap;
        }
    }
    main {
        margin-top: 16px;
        height: calc(100% - 128px);
        padding: 16px 24px 58px 24px;
        box-sizing: border-box;
        background: #ffffff;
        box-shadow: 0 0 10px -6px rgba(0, 0, 0, 0.3);
        border-radius: 2px;
        .subToolbar {
            text-align: right;
            margin-bottom: 16px;
            .el-button {
                margin-right: 8px;
            }
        }
        .table {
            height: calc(100% - 46px);
            .table-header {
                height: calc(100% - 40px);
            }
            .pagination {
                text-align: right;
                margin-top: 10px;
            }
        }
        .status {
            display: inline-block;
            width: 68px;
            height: 24px;
            text-align: center;
            line-height: 24px;
            &.status0 {
                background: rgba(68, 203, 87, 0.2);
                color: #44cd40;
            }
            &.status1 {
                background: rgba(255, 127, 127, 0.2);
                color: #ff5c5c;
            }
        }
        /deep/.el-table th.el-table__cell {
            background: #f6f8fa;
        }
    }
}
/deep/ .el-date-editor{
    width:16vw !important
}
</style>
